import { useContext } from 'react'
import style from './Sidebar.module.css'
import { AppContext, DialogueContext, RTLContext, SideBarContext } from '../../App'
import BrowseRootTerms from '../../BrowseTerms/browseRootTerms'
import { NavLink } from 'react-router-dom';
import Privilage from '../../privilage';
import { faBookOpen, faFlag, faUserCog, faBars, faCommentAlt, faDoorOpen, faBell, faTag } from "@fortawesome/free-solid-svg-icons"
import SidebarItem from '../menu_item/MenuItem'
import UseSidebar from './useSidebar'

const REFRESH = 7

function Sidebar() {
    const appContext = useContext(AppContext)
    const rtlContext = useContext(RTLContext)
    const sidebarContext = useContext(SideBarContext)
    const dialogueContext = useContext(DialogueContext)
    const controller = UseSidebar()

    const customStyle = {}
    if (rtlContext) {
        if (sidebarContext.value) customStyle.right = 0;
        else customStyle.right = -225
    }
    else {
        if (sidebarContext.value) customStyle.left = 0
        else customStyle.left = -225
    }
    return <div className={style["sidebar"]} style={customStyle} >
        <div className={style["profile"]}>
            <img src={appContext.value.getIcon()} alt="profile_picture" />
            <h3>{appContext.value.getName()}</h3>
        </div>
        {
            controller.isLoggedIn() ?
                <ul>
                    <SidebarItem title="تصفح المواد" icon={faBookOpen} onClick={() => dialogueContext.editDialogue({ visible: true, content: <BrowseRootTerms /> })} />
                    <NavLink to="/questionsTags">
                        <SidebarItem title="التصنيفات" icon={faTag} />
                    </NavLink>
                    <NavLink to="/reports">
                        <SidebarItem title="التبليغات" icon={faFlag} />
                    </NavLink>
                    <Privilage privilageId="8"><NavLink to="/admins">
                        <SidebarItem title="المديرين" icon={faUserCog} />
                    </NavLink></Privilage>
                    <Privilage privilageId="11"><NavLink to="/couponsTypes">
                        <SidebarItem title="الكروت" icon={faBars} />
                    </NavLink></Privilage>
                    <Privilage privilageId="5">
                        <SidebarItem title="إرسال إشعار" icon={faCommentAlt}
                            onClick={() => {
                                controller.showNotificationDialogue('', '')
                            }} />
                    </Privilage>
                    <Privilage privilageId="5">
                        <NavLink to="/notifications">
                            <SidebarItem title="الإشعارات المرسلة" icon={faBell} />
                        </NavLink>
                    </Privilage>
                    <NavLink to="/">
                        <SidebarItem title="خروج" icon={faDoorOpen} onClick={e => {
                            localStorage.clear();
                            controller.dispatch({ type: REFRESH })
                        }} />
                    </NavLink>
                </ul> : <></>
        }
    </div >
}
export default Sidebar