import useContent from './useContent';
import { Route } from "react-router-dom";
import { lazy } from 'react'
import Privilage from '../privilage';

const AddQuestions = lazy(() => import('../AddQuestions/addQuestions'))
const Reports = lazy(() => import("../Reports/Reports"))
const BrowseTerms = lazy(() => import("../BrowseTerms/browseTerms"))
const Admins = lazy(() => import('../Admins/Admins'))
const User = lazy(() => import('../user/User'))
const Term = lazy(() => import('../BrowseTerms/term'))
const CouponsTypes = lazy(() => import('../Coupons/CouponsTypes'))
const CouponsType = lazy(() => import('../Coupons/CouponsType'))
const CouponSearch = lazy(() => import('../Coupons/Search'))
const Coupons = lazy(() => import("../Coupons/Coupons"))
const Admin = lazy(() => import("../Admin/Admin"))
const AddAdmin = lazy(() => import("../Admins/AddAdmin/AddAdmin"))
const SimilarQuestions = lazy(() => import("../Questions/Similar/SimilarQuestions"))
const QuestionsSearch = lazy(() => import('../Questions/search/Search'))
const QuestionsTags = lazy(() => import("../QuestionsTags/QuestionsTags"))
const SearchQuestionsByTag = lazy(() => import("../Questions/QuestionsTags/QuestionsTags"))
const QuestionsTag = lazy(() => import("../QuestionsTags/QuestionsTag/QuestionsTag"))
const AddQuestionsChildTag = lazy(() => import("../QuestionsTags/AddChildTag/AddChildTag"))
const Notifications = lazy(() => import("../Notifications/Notifications"))
const EditQuestionTag = lazy(() => import("../QuestionsTags/EditQuestionTag/EditQuestionTag"))
const SearchQuestionsTags = lazy(() => import("../QuestionsTags/SearchQuestionsTags/SearchQuestionsTags"))
const TermQuestionsTags = lazy(() => import("../QuestionsTags/TermQuestionsTags/TermQuestionsTags"))

function Content() {

    const { isLoggedIn } = useContent();
    return <div>
        {
            isLoggedIn ?
                [<Route path={["/termId", "/termId/*"]} component={Term} />
                    , <Route path={["/addQuestions/*"]} component={AddQuestions} />
                    , <Route path={["/question/:id/similar"]} component={SimilarQuestions} />
                    , <Route path="/admins" exact component={Admins} />
                    , <Route path="/user/:id" component={User} />
                    , <Route path="/reports" component={Reports} />
                    , <Route path="/couponsTypes/(termCycle)?/:termCycle?" exact component={CouponsTypes} />
                    , <Route path="/couponsType/:couponType/(termCycle)?/:termCycle?" exact component={CouponsType} />
                    , <Route path="/coupons/(termCycle)?/:termCycle?" exact component={Coupons} />
                    , <Route path="/admin/:id" component={Admin} />
                    , <Route path="/coupons/search/*" component={CouponSearch} />
                    , <Route path="/questions/search/:query/(skip)?/:skip?" component={QuestionsSearch} />
                    , <Route path="/admins/add" component={AddAdmin} />
                    , <Route path="/questionsTags" exact component={QuestionsTags} />
                    , <Route path="/questionsTags/:tagId" exact component={QuestionsTag} />
                    , <Route path="/questionsTags/:tagId/edit" exact component={EditQuestionTag} />
                    , <Route path="/questions/tag/:tagId/(skip)?/:skip?" component={SearchQuestionsByTag} />
                    , <Route path="/questionsTags/search/:query" component={SearchQuestionsTags} />
                    , <Route path="/questionsTags/termId/:termId" component={TermQuestionsTags} />,
                <Privilage privilageId="10" >
                    <Route path="/questionsTags/:tagId/add" component={AddQuestionsChildTag} />
                </Privilage>,
                <Privilage privilageId="5" >
                    <Route path="/notifications" component={Notifications} />
                </Privilage>,
                <Route path="/browseTerms" component={BrowseTerms} />]
                : <></>
        }
    </div >
}
export default Content