import style from './Navbar.module.css'
import style2 from '../secondary_nav_bar/SecondaryNavBar.module.css'
import { SideBarContext } from '../../App'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { useContext } from 'react'
import Searchbar from '../SearchBar/Searchbar'
import useNavbar from './useNavbar'
import NavbarLink from '../navbar_link/NavbarLink'
import { NavLink } from 'react-router-dom'

function NavBar() {
    const sidebarContext = useContext(SideBarContext)
    const controller = useNavbar()

    return <div className={style.top_navbar}>

        <div className={`${style2.group}`}>
            <FontAwesomeIcon className={`${style.hamburger}`} icon={faBars} onClick={e => sidebarContext.invoke()} />
        </div>
        <div>
            <Searchbar />
        </div>
        <div className={style2.group} style={{ flexDirection: "row-reverse" }}></div>

    </div >

}
export default NavBar