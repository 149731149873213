import app from './App.module.css';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import React, { Suspense } from 'react'
import Dialogue from './components/dialogue'
import Sidebar from './components/sidebar/Sidebar';
import AppConfig from './config/appConfig';
import NavBar from './components/navbar/Navbar';
import Toast from './components/toast/Toast';
import Loading from './components/loading/Loading';
import Content from './Content/Content';
import useSecondaryNavBar from './components/secondary_nav_bar/useSecondaryNavBar';
import SecondaryNavBar from './components/secondary_nav_bar/SecondaryNavBar';
import useApp from './useApp';

export const SignInDATA = React.createContext()
export const Privilages = React.createContext()
export const DialogueContext = React.createContext()
export const AppContext = React.createContext(new AppConfig())
export const SideBarContext = React.createContext()
export const RTLContext = React.createContext()
export const PageContext = React.createContext()
export const SecondaryNavBarContext = React.createContext()

const SHOW_MESSAGE = 3

function App() {
  const controller = useApp()
  const {
    state,
    confirmDialogueButtons,
    confirmDialogueContent,
    confirmDialogueTitle,
    confirmDialogueVisibility,
    signInVisibile,
    isLoggedIn,
    dispatch,
    hideToast,
    showToast,
    hideNoticeMessage,
    showNoticeMessage,
    showLoading,
    customStyle,
    setSideBarActiveItem,
    invokeSideBar,
    setPrivilages,
    clearDialogueChildren,
    addDialogueChild,
    editDialogue,
    hideDialogue
  } = controller

  return (
    <Router getUserConfirmation={(message, callback) => {
      // this is the default behavior
      const allowTransition = window.confirm(message);
      callback(allowTransition);
    }}>
      <AppContext.Provider value={{
        value: state.appConfig,
      }}>
        <DialogueContext.Provider value={{
          hideDialogue: hideDialogue,
          editDialogue: editDialogue,
          addChild: addDialogueChild,
          clearChildren: clearDialogueChildren
        }}>

          <Privilages.Provider
            value={{
              admin: state.admin,
              privilages: state.privilages,
              setPrivilages: setPrivilages
            }}>
            <RTLContext.Provider value={state.rtl}>
              <SideBarContext.Provider value={{ value: state.sideBarVisible, invoke: invokeSideBar, activeItem: state.activeSideBarItem, setActiveItem: setSideBarActiveItem }}>
                <SecondaryNavBarContext.Provider value={useSecondaryNavBar()}>
                  <PageContext.Provider value={{
                    loading: state.loading, message: state.message,
                    showLoading: showLoading, showNoticeMessage: showNoticeMessage,
                    hideNoticeMessage: hideNoticeMessage, showToast: showToast, hideToast: hideToast
                  }}>
                    <div className={app.App} style={{ direction: state.rtl ? "rtl" : "ltr" }}>
                      <div className={`${app.section} ${app.fixed}`} style={customStyle}>
                        <NavBar />
                        <SecondaryNavBar />
                        <Sidebar />
                        {
                          state.loading ? <Loading /> : ''
                        }
                      </div>
                      <div className={app.section} style={customStyle}>
                        <div style={{ opacity: 0 }}>
                          <NavBar />
                          <SecondaryNavBar hidden />
                          {
                            state.loading ? <Loading /> : ''
                          }
                        </div>
                        {
                          state.message != '' ? state.message : ''
                        }
                        <Suspense fallback={<Loading />}>
                          <Switch>
                            <Content />
                          </Switch>
                        </Suspense>
                        <SignInDATA.Provider
                          value={{
                            visibility: state.signInVisibile,
                            message: state.message,
                            SHOW_MESSAGE: SHOW_MESSAGE,
                            dispatch: dispatch
                          }}
                        >
                          {
                            !isLoggedIn() ?
                              <div className={app["login-page"]}>
                                <div className={app["form"]}>
                                  {
                                    signInVisibile ? <SignIn />
                                      : <SignUp />
                                  }
                                </div>
                              </div> : <></>
                          }

                        </SignInDATA.Provider>
                        <Dialogue visible={confirmDialogueVisibility} title={confirmDialogueTitle} content={confirmDialogueContent} cont={DialogueContext}>
                          {
                            confirmDialogueButtons
                          }
                        </Dialogue>
                        <div className={`${app["notification-container"]} ${app['top-right']}`}>
                          {
                            state.toastList.map((toast) => {
                              return <Toast
                                toast={toast}
                                key={toast.id}
                                position="top-right"
                              />
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </PageContext.Provider>
                </SecondaryNavBarContext.Provider>
              </SideBarContext.Provider>
            </RTLContext.Provider>
          </Privilages.Provider>
        </DialogueContext.Provider>
      </AppContext.Provider>
    </Router >
  );
}
export default App;