import { useReducer, useEffect, useContext } from "react"
import { PageContext } from '../../App';

const DISMISS_ANIMATION = 1
const UseToast = (props) => {
    const { toast } = props;
    const pageContext = useContext(PageContext)

    const reducer = (state, action) => {
        switch (action.type) {

            case DISMISS_ANIMATION:
                console.log(state);
                return {
                    ...state,
                    dismissed: true
                }
        }
        return state
    }

    const [state, dispatch] = useReducer(reducer, toast)

    function setDismissTimeout() {
        setTimeout(() => {
            dispatch({
                type: DISMISS_ANIMATION,
            })
            setTimeout(() => {
                pageContext.hideToast(toast.id)
            }, 700)
        }, 2500);
    }
    useEffect(() => {
        setDismissTimeout()
    }, [toast]);

    return {
        state: state
    }
}
export default UseToast