// Perfect
// export const AppId = 1
// export const rootUrl = 'http://perfect-app.atwebpages.com/'

//Be Ready
export const AppId = 2
export const rootUrl = 'http://be-ready.atwebpages.com/'

//Durous
// export const AppId=3

//DELTA
// export const rootUrl = 'http://delta-app.atwebpages.com/'
// export const AppId = 1

// MRI
// export const AppId = 5
// export const rootUrl = 'http://mri.scienceontheweb.net/'