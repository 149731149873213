import { useReducer, useContext } from 'react'
import { DialogueContext, PageContext } from '../App';
import Network from '../utilities/Network';
const FILL_FIELD = 1
export default function useSignIn() {
    const dialogueContext = useContext(DialogueContext)
    const pageContext = useContext(PageContext)

    const reducer = (state, action) => {
        switch (action.type) {
            case FILL_FIELD://field value
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        [action.field]: action.value
                    }
                }
            default: return state
        }
    }

    const initialState = {
        fields: {
            username: '',
            password: ''
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)


    function handleSubmit(event) {
        event.preventDefault()
        new Network({
            url: "/adminLogIn.php",
            method: "post",
            context: pageContext,
            dialogueContext: dialogueContext,
            params: {
                'username': state.fields.username,
                'password': state.fields.password
            },
            onConnectionSucceed: (data) => {
                localStorage.setItem("admin", JSON.stringify(data.admin))
            }
        }).invoke();
    }
    return { handleSubmit, state, dispatch }
}
