import React, { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { RTLContext } from '../App'

function Dialogue({ visible = false, title = "تنبيه", content, children, cont }) {
    const context = useContext(cont)
    return <Modal show={visible} centered onHide={context.hideDialogue}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>{content}</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={context.hideDialogue}>إلغاء</Button>
            {children}
        </Modal.Footer>
    </Modal>
}

export default Dialogue
